import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { alertActions } from '../../actions';
import { PrivateRoute, Footer } from '../../components';
import { connect } from 'react-redux';
import { Nav } from '../../components'
import { RalsList, RalsCreate } from './index'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

class RalsPage extends React.Component {

    render() {
        
        const { alert } = this.props;
        const { match, location } = this.props
        console.log(`${match.path}/add`)
        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Nav />
                    </Grid>
                    {alert.message &&
                        <div className={`alert ${alert.type}`}>{alert.message}</div>
                    }
                    <Switch>
                        <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
                        <PrivateRoute path={match.path} component={RalsList} />
                        <PrivateRoute path={`${match.path}/add`} component={RalsCreate} />
                        {/* <PrivateRoute path={`${match.path}/edit/:id`} component={VehiclesCreateUpdate} />  */}
                    </Switch>
                </Grid>
                <Footer/>
            </Container>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(RalsPage);
export { connectedApp as RalsPage };