import React from 'react';
import { LoadingSpinner,AlertAux } from '../../components';
import { withTranslation } from 'react-i18next';

import {
    Typography,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    TablePagination,
    Grid
} from '@material-ui/core';
import { connect } from 'react-redux';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Header } from '../../components/Header';
import { userService } from '../../services';
import { companiesService } from '../../services/companies_service';
import { UsersRow } from './UsersRow';

class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            filteredUsers: null,
            page: 0,
            rowsPerPage: 5,
            alertMessage: null,
            alertType: '',
            company_name: ''
        };
        this.updateUsers = this.updateUsers.bind(this);
        this.findUser = this.findUser.bind(this);
    }

    findUser(id) {

    }

    updateUsers() {
        companiesService.childrens_companies(this.props.user.company_identifier)
        .then(companies => {
            companies.forEach(company => {
                if(company.company_identifier.toString() === this.props.user.company_identifier.toString()) {
                    this.setState(oldState => {
                        return {
                            ...oldState,
                            company_name: company.company_name,
                        }
                    })
                }
            })
            userService.list()
            .then(result => {
                const newUsers = [];
                result.forEach((user) => {
                    companies.forEach(company => {
                        if (company.company_identifier.toString() === user.company_identifier.toString()) {
                            newUsers.push({
                                ...user,
                                company_name: company.company_name,
                            })
                        }
                    })
                })
                this.setState({users: newUsers, filteredUsers: newUsers})
            }).catch(v => {
                this.setState({
                    alertMessage: String(v), 
                    alertType: 'error',
                })
            });
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',
            })
        });
    }

    componentDidMount() {
        this.updateUsers();
    }

    render() {
        const { t, classes } = this.props;
        const { page, rowsPerPage, alertMessage, alertType, users, company_name } = this.state;
        
        return (
        <Grid item xs={12}>
            <Header
                props={ this.props }
                title='users'
                phrase='username'
                search='search_user_idenfier'
                update={this.findUser}
                company_name={ company_name }
            />
            
            <TableContainer component={Paper}>
                <Table className={Styles.table_default} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>{t('uuid')}</TableCell>
                            <TableCell align="right">{t('username')}</TableCell>
                            <TableCell align="right">{t('company_identifier')}</TableCell>
                            <TableCell align="right">{t('ssl-admin')}</TableCell>
                            <TableCell align="right">{t('ssl-user')}</TableCell>
                            <TableCell align="right">{t('ssl-reader')}</TableCell>
                            <TableCell align="right">{t('ssl-technician')}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users && users
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(user => (
                            <UsersRow 
                                props={this.props} 
                                userItem={user}
                                key={ user.uuid }

                            />
                        ))}
                    </TableBody>
                </Table>
                
            </TableContainer>
            {users &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={users.length}
                    page={page}
                    labelRowsPerPage={t('rows_per_page')}
                    // onChangePage={this.handleChangePage}
                    // onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            }
            {!users &&
                <LoadingSpinner></LoadingSpinner>
            }
            {users && !users.length &&
                <Typography className={classes.sec_title_default}>{t('no_users_to_display')}</Typography>
            }
            {alertMessage ?
                <AlertAux message={alertMessage} type={alertType}></AlertAux>
                : null
            } 
        </Grid>
        );
    }
}

UsersList.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedUsersList = connect(mapState)(UsersList);
const translatedUsersList = withTranslation()(connectedUsersList);
const styledUsersList = withStyles(Styles)(translatedUsersList)
export { styledUsersList as UsersList };