import React from 'react';
import { LoadingSpinner } from '../../components';
import { withTranslation } from 'react-i18next';
import {
    TableCell,
    TableRow,
    Collapse,
    IconButton,
    DialogTitle,
    Dialog,
    Button,
    DialogActions,
    Paper,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { connect } from 'react-redux';
import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import PanToolIcon from '@material-ui/icons/PanTool';
import { toast } from 'react-toastify';

class DevicesRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            open: false,
            confirmationDialog: false,
            isDeleting: false,
            isEditingOwner: true,
            companies: [],
            owner: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.enableOwnerChange = this.enableOwnerChange.bind(this);
    }

    handleChange (event) {
        const { value } = event.target;
        this.setState({ owner: value });
    }

    componentDidMount() {
        this.setState(oldState => (
            {
                ...oldState,
                companies: this.props.companies,
                owner: this.props.companies
            }
        ))
    }

    async enableOwnerChange() {
        const { t } = this.props;
        await this.setState(oldState => {
            return {
                ...oldState,
                isEditingOwner: !oldState.isEditingOwner,
            }
        });
        !this.state.isEditingOwner && toast.success(t('update_success'));
    }

    render() {
        const device = this.props.device;
        const { t, classes } = this.props;
        const { 
            open,
            confirmationDialog,
            isDeleting,
            isEditingOwner,
            owner,
            companies
        } = this.state;

        var datetime = null;
        if (null != device.last_read_timestamp) {
            var d = new Date(device.last_read_timestamp * 1000);
            datetime = d.toLocaleString();
        }
        

        return (
            <>
                <TableRow
                    key={device.uuid}
                >
                    <TableCell>
                        {/* <IconButton
                            aria-label="expand row"
                            size="small" 
                            onClick={() => this.setState({open : !open})}
                        >
                            {open ? 
                                <KeyboardArrowUpIcon /> : 
                                <KeyboardArrowDownIcon/>
                            }
                        </IconButton> */}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                    >
                        {device.obuid}
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {device.type}
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {device.alias}
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {device.vbat} 
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {null != datetime ? datetime : ''} 
                    </TableCell>


                    <TableCell align="right">
                            {/* <IconButton
                                onClick={() => this.setState({confirmationDialog: true})}
                                linkButton={true} 
                                variant="contained"
                                className={classes.icon_default}
                                >
                                {isDeleting 
                                    ? <LoadingSpinner></LoadingSpinner>
                                    : <PanToolIcon />
                                }
                            </IconButton> */}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {/* <Paper elevation={2}>
                                <Box padding="20px">
                                    <div>
                                        <Typography
                                            className={classes.sec_title_default}
                                            variant="h5"
                                            gutterBottom
                                            component="div"
                                        >
                                            {t('owner_information')}
                                        </Typography>
                                    </div>
                                    <div>
                                        <form>
                                            <div>
                                                <FormControl>
                                                    <InputLabel>{t('owner_name')}</InputLabel>
                                                    <Select
                                                        labelId="owner"
                                                        id="owner"
                                                        value={owner}
                                                        name="owner"
                                                        onChange={this.handleChange}
                                                        className={ classes.select_owner }
                                                    >
                                                        {companies && companies.map(v =>
                                                            <MenuItem value={v.company_name}>{ v.company_name }</MenuItem>
                                                        )}
                                                            <MenuItem value={'empty'}>{t('no_owners')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {   owner.company_name &&
                                                    <Typography 
                                                        variant="caption" 
                                                        display="block" 
                                                        className="form-text text-muted"
                                                    >
                                                            {t('max_of_characters')} 30
                                                    </Typography>
                                                }
                                            </div>
                        
                                            <div>
                                                {
                                                    isEditingOwner &&
                                                    (
                                                        <Button
                                                            className={classes.button_default}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={this.enableOwnerChange}
                                                        >
                                                            { t('save') + ' ' + t('company') }
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </Box>
                            </Paper> */}
                        </Collapse>
                    </TableCell>
                </TableRow>

            <Dialog 
                onClose={() => this.setState({confirmationDialog: false})} 
                aria-labelledby="simple-dialog-title" 
                open={confirmationDialog}
                fullWidth='sm'
                maxWidth='sm'
            >
                    <DialogTitle 
                        id="simple-dialog-title"
                        className={classes.sec_title_default}
                    >
                        {t('confirm_claim') + ' ?'}
                    </DialogTitle>
                    <DialogActions>
                        <Button 
                            onClick={() => this.deleteVehicle(device.uuid)} 
                            color="primary"
                            variant="contained" 
                            className={classes.button_green_default}
                            >
                            {t('yes')}
                        </Button>
                        <Button 
                            onClick={() => this.setState({confirmationDialog: false})}
                            color="primary" 
                            variant="contained" 
                            autoFocus
                            className={classes.button_red_default}
                            >
                            {t('no')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

DevicesRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedDevicesRow = connect(mapState)(DevicesRow);
const translatedDevicesRow = withTranslation()(connectedDevicesRow);
const styledDevicesRow = withStyles(Styles)(translatedDevicesRow)
export { styledDevicesRow as DevicesRow };
