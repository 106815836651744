import config from '../config';
import { handleResponse, authHeader } from '../helpers';

export const companiesService = {
    list,
    childrens_companies,
    create,
    update
};

// company
// {
//     "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     "company_identifier": 655351,
//     "company_name": "YAC - Yet Another Company"
// }

function list(id) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.API_EHOOK}${config.COMPANIES}`, requestOptions)
        .then(handleResponse)
        .then(companies => {
            const filteredCompanies = [];
            companies.forEach(company => {
                if (id.toString() === company.company_identifier.toString()) {
                    filteredCompanies.push(company);
                }
            })
            return filteredCompanies;
        });
}

function childrens_companies(company_identifier) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.API_EHOOK}${config.CHILDRENSANDPARENT}?companyId=${company_identifier}`, requestOptions)
        .then(handleResponse)
        .then(companies => {
            return companies;
        });

}

function create(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    
    return fetch(`${config.API_EHOOK}${config.CREATECOMPANY}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function update(data) {

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    
    return fetch(`${config.API_EHOOK}${config.UPDATECOMPANY}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}
