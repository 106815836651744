import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageIcon from '@material-ui/icons/Language';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import i18n from '../i18n';

import { Styles } from '../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

function Nav(props) {
    const { classes } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useState(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleClose()
    }

    const checkUserPermissions = () => {
        const local = JSON.parse(localStorage.getItem('user'));
        setUser(local);
    }

    useEffect(() => {
        checkUserPermissions();
    }, [props])

    return (
        <AppBar 
            position="relative" 
            className={classes.navbar}>
            <Toolbar className={ classes.navbar_container }>      
                    <Button 
                        color="inherit" 
                        linkButton={true} 
                        variant="text"
                        className={classes.navbar_text} 
                        component={NavLink} to={"/"}>
                        {t('home')}
                    </Button>
                    <div className={ classes.navbar_divisor } />
                    <Button 
                        color="inherit" 
                        linkButton={true} 
                        variant="text"
                        className={classes.navbar_text} 
                        component={NavLink} to={"/vehicles"}>
                        {t('vehicles')}
                    </Button>
                    <div className={ classes.navbar_divisor } />
                    <Button 
                        color="inherit" 
                        linkButton={true} 
                        variant="text"
                        className={classes.navbar_text} 
                        component={NavLink} to={"/rals"}>
                        {t('rals')}
                    </Button>
                    <div className={ classes.navbar_divisor } />
                    {
                        user && user.roles.includes('SSL-ADMIN') && 
                        (
                            <>
                                <Button 
                                    color="inherit" 
                                    linkButton={true} 
                                    variant="text"
                                    className={classes.navbar_text} 
                                    component={NavLink} to={"/companies"}>
                                    {t('companies')}
                                </Button>
                                <div className={ classes.navbar_divisor } />
                            </>
                        )
                    }
                    <Button 
                        color="inherit" 
                        linkButton={true} 
                        variant="text"
                        className={classes.navbar_text} 
                        component={NavLink} to={"/users"}>
                        {t('users')}
                    </Button>
                    <div className={ classes.navbar_divisor } />
                    {
                        user && user.roles.includes('SSL-ADMIN') && 
                        (
                            <>
                                <Button 
                                    color="inherit" 
                                    linkButton={true} 
                                    variant="text"
                                    className={classes.navbar_text} 
                                    component={NavLink} to={"/devices"}>
                                    {t('devices')}
                                </Button>
                                <div className={ classes.navbar_divisor } />
                            </>
                        )
                    }
                    <div className={ classes.navbar_icons }>
                        <IconButton
                            style={{position: 'relative'}}
                            edge="end"
                            aria-label="logout"
                            component={NavLink} to={"/login"}
                            color="inherit"
                            >
                            <ExitToAppIcon />
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <LanguageIcon />
                        </IconButton>
                    </div>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => {changeLanguage('pt')}}>PT-BR</MenuItem>
                        <MenuItem onClick={() => {changeLanguage('en')}}>EN-US</MenuItem>
                    </Menu>
            </Toolbar>
        </AppBar>
    );
}

Nav.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styledNav = withStyles(Styles)(Nav)
export { styledNav as Nav };