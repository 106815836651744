import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner, AlertAux, Footer } from '../../components';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider'
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { companiesService } from '../../services/companies_service';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';


class CompanyCreate extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {      
            isAddMode: !this.props.match.params.id,
            id: this.props.match.params,
            isLoading: true,
            submitted: false,
            alertMessage: null,
            alertType: '',
            name: '',
            company_identifier: '',
        };  
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createCompany = this.createCompany.bind(this);
    }

    createCompany() {
        const { name } = this.state;
        if (name.length < 3) {
            this.setState({ isLoading: false});
            return;
        } else {
            const createData = {
                name: name,
                type: 0
            }
    
            return companiesService.create(createData)
                .then(v => {
                    this.setState(
                    {
                        alertMessage: this.props.t('create_success'), 
                        alertType: 'success',
                        isLoading: false,
                        submitted: false,
                    })
                })
                .catch(v => {          
                    this.setState({
                        alertMessage: v, 
                        alertType: 'error',
                        isLoading: false,
                        submitted: false,
                    })     
                });
        }
    }

    updateCompany() {
    }


    handleChange (event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit (event) {
        event.preventDefault();
        
        this.setState({ submitted: true})
        const {
            name,
        } = this.state;

        var companyAux = this.state
        if(this.state.isAddMode){
            const { user } = this.props;
            companyAux = user.company_identifier
        }
        const company_identifier = companyAux;

        if( (name || this.state.isAddMode) &&
            company_identifier)
        {
            this.setState({ isLoading: true});
            this.state.isAddMode
            ? this.createCompany(this.state)
            : this.updateCompany(this.state);
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false});
    }

    render(){
        const isAddMode = this.state.isAddMode;
        const { t } = this.props;
        const {
            name,
        } = this.state;
        const isLoading = this.state.isLoading;
        const submitted = this.state.submitted;
        const { alertMessage, alertType } = this.state;
        const { classes } = this.props

        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.title_container}>
                        <Typography 
                            variant="h3" 
                            component="h1" 
                            gutterBottom
                            className={classes.title_default}
                        >{isAddMode ? (t('add')+" "+t('company')) : (t('edit')+" "+t("company"))}
                        </Typography>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={6} spacing={0}>
                        {isLoading ?
                            <LoadingSpinner></LoadingSpinner>
                        :
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                            <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('name')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="name" 
                                            value={name} 
                                            onChange={this.handleChange}/>
                                        {submitted && !name &&
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                className="form-text text-muted">{t('required')}</Typography>
                                        }
                                    </FormControl>
                                </div>
                                             
                            </div>
                            
                            <div className="form-group">
                                <Button 
                                    disabled={isLoading}
                                    variant="contained" 
                                    color="primary"
                                    type="submit"
                                    className={classes.button_default}
                                >{isLoading && <LoadingSpinner/>}{t('save')}</Button>
                                <Button
                                    className={classes.button_default}
                                    color="primary" 
                                    linkButton={true} 
                                    variant="contained" 
                                    component={Link} to={isAddMode ? '.' : '..'}>
                                    {t('return')}
                                </Button>
                            </div>        
                        </form>
                        }                                                
                        {alertMessage ?
                            <AlertAux message={alertMessage} type={alertType}></AlertAux>
                            : null
                        }   
                    </Grid>
                </Grid>
                <Footer/>
            </Container>
        );
    }
}

CompanyCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const actionCreators = {

}

const connectedCompanyCreate = connect(mapState, actionCreators)(CompanyCreate);
const translatedCompanyCreate = withTranslation()(connectedCompanyCreate);
const styledCompanyCreate = withStyles(Styles)(translatedCompanyCreate)
export { styledCompanyCreate as CompanyCreate };