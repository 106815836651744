import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { store } from './helpers';
import { App } from './App';
import './i18n';
import { Suspense } from 'react';

render(  
    <Provider store={store}>
        <Suspense fallback="loading">
            <BrowserRouter>           
                <App />
            </BrowserRouter>
        </Suspense>
    </Provider>

    ,document.getElementById('app')
);