import config from '../config';
import { handleResponse, authHeader } from '../helpers';

// vehicle
/*
{
    "uuid": "88f56636-04d3-11eb-941d-02420a002d08",
    "license_plate": "AAA0001",
    "type": "truck",
    "city": "São Paulo",
    "state": "SP",
    "country": "BR",
    "company_identifier": "65575",
    "device_list": [
        {
            "type": "ssl",
            "obuid": "1870624410",
            "alias": "000D6F7F769A"
        }
    ]
},
*/

export const vehicleService = {
    list,
    getOne,
    create,
    update,
    getPassword,
    _delete
};

function getPassword(userHash, obuid, password){
    ///counterpass?obuid=fff&userHash=fff&
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    const userHashEncVal = encodeURIComponent(userHash)
    const obuidEncVal = encodeURIComponent(obuid)

    // return fetch(`${config.API_EHOOK}${config.VEHICLE}`, requestOptions)
    return fetch(`${config.API_EHOOK}${config.PASSWORD}?userHash=${userHashEncVal}&obuid=${obuidEncVal}&password=${password}`, requestOptions)
        .then(handleResponse)
        .then(pass => {
            return pass;
        })
        .catch(pass => {
            return { error: true };
        });  
  }

function list() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.API_EHOOK}${config.VEHICLE}`, requestOptions)
        .then(handleResponse)
        .then(vehicles => { 
            return vehicles;
        });
}

function getOne(data){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    const encodedValue = encodeURIComponent(data.id)

    return fetch(`${config.API_EHOOK}${config.VEHICLE}?uuid=${encodedValue}`, requestOptions)
        .then(handleResponse)
        .then(vehicle => {
            return vehicle;
        });
}

function create(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    
    return fetch(`${config.API_EHOOK}${config.VEHICLE}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function update(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    console.log(JSON.stringify(data))
    return fetch(`${config.API_EHOOK}${config.VEHICLE}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(uuid){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    const encodedValue = encodeURIComponent(uuid)

    return fetch(`${config.API_EHOOK}${config.VEHICLE}?uuid=${encodedValue}`, requestOptions)
        .then(handleResponse)
        .then(vehicle => {
            return vehicle;
        });
}