import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner, AlertAux, Footer } from '../../components';
import { ralService, vehicleService } from '../../services'
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'

import Grid from '@material-ui/core/Grid';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';

class RalsCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      

        };  
        this.state = {      
            isLoading: true,
            submitted: false,
            alertMessage: null,
            alertType: '',
            vehicles: [],
            latitude: 0,
            longitude: 0,
            radius: 0,
            owner: '',
            //yyyy-MM-ddThh:mm necessary for the select component
            max: moment((new Date())).format("YYYY-MM-DDTkk:mm"),
            min: moment((new Date())).format("YYYY-MM-DDTkk:mm"),
            vehicle: null,
            uuid: ''
        };  
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange (event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit (event) {
        event.preventDefault();
        this.setState({ submitted: true})
        if( this.state.latitude &&
            this.state.longitude && 
            this.state.max && 
            this.state.min && 
            this.state.radius &&
            this.state.uuid )
        {
            this.setState({ isLoading: true});
            this.createRal(this.state)
        }
    }

    componentDidMount(){
        vehicleService.list()
        .then(vehicles => {

            var vehiclesWithDevice = vehicles.filter(function(item) {
                return item.device_list.length > 0;
            });
            
            if(vehiclesWithDevice.length > 0)
            {
                this.setState({
                    vehicles: vehiclesWithDevice,
                    vehicle: vehiclesWithDevice[0],
                    uuid: vehiclesWithDevice[0].uuid,
                    isLoading: false
                })  
            }
        })
        .catch(errorMessage => {          
            this.setState({
                alertMessage: String(errorMessage), 
                alertType: 'error',
                isLoading: false,
                submitted: false,
            })     
        });
    }
    
    createRal(state) {
        
        const createData = {
            latitude: state.latitude,
            longitude: state.longitude,
            radius: state.radius,
            owner: state.owner,
            timestamp_min: (new Date(state.min).getTime()/1000),
            timestamp_max: (new Date(state.max).getTime()/1000),
            vehicle: {
                uuid: state.uuid
            }
        }

        return ralService.create(createData)
            .then(v => {
                this.setState(
                {
                    alertMessage: this.props.t('create_success'), 
                    alertType: 'success',
                    isLoading: false,
                    submitted: false,
                })
            })
            .catch(v => {          
                this.setState({
                    alertMessage: v, 
                    alertType: 'error',
                    isLoading: false,
                    submitted: false,
                })     
            });
    }

    getLoggedUsername() {
        let loggedInfo = JSON.parse(localStorage.getItem('user'))        
        return loggedInfo.user
    }

    render() {        
        const { latitude, longitude, max, min, radius, uuid, vehicles } = this.state
        let { owner } = this.state
        const { t } = this.props;
        const { isLoading, submitted } = this.state;
        const { alertMessage, alertType } = this.state;
        const { classes } = this.props;

        if (owner === null || owner.length === 0) {
            owner = this.getLoggedUsername()
        }

        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.title_container}>
                        <Typography 
                            variant="h3" 
                            gutterBottom
                            className={classes.title_default}
                            >
                            {t('create')+" "+t('ral')}
                        </Typography>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        {!vehicles &&
                            <LoadingSpinner></LoadingSpinner>
                        }
                        {vehicles && !vehicles.length &&
                            <Typography className={classes.title_default}>{t('no_vehicles_to_display')}</Typography>
                        }
                        {vehicles && vehicles.length > 0 &&
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <Typography className={classes.title_default}>{t('location')}</Typography>                           
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('latitude')}</InputLabel>
                                        <Input 
                                            type="number"
                                            name="latitude" 
                                            value={latitude} 
                                            onChange={this.handleChange}/>
                                            {submitted && !latitude &&
                                                <Typography 
                                                    variant="caption" 
                                                    display="block" 
                                                    className="form-text text-muted">{t('required')}</Typography>
                                            }
                                    </FormControl>
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('longitude')}</InputLabel>
                                        <Input 
                                            type="number"
                                            name="longitude" 
                                            value={longitude} 
                                            onChange={this.handleChange}/>
                                            {submitted && !longitude &&
                                                <Typography 
                                                    variant="caption" 
                                                    display="block" 
                                                    className="form-text text-muted">{t('required')}</Typography>
                                            }
                                    </FormControl>
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('radius')}</InputLabel>
                                        <Input 
                                            type="number"
                                            name="radius" 
                                            value={radius} 
                                            onChange={this.handleChange}/>
                                            {submitted && !radius &&
                                                <Typography 
                                                    variant="caption" 
                                                    display="block" 
                                                    className="form-text text-muted">{t('required')}</Typography>
                                            }
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <Typography className={classes.title_default}>{t('liberation_period')}</Typography>
                                    <TextField
                                        className={classes.input_default}
                                        id="min"
                                        label={t('min')}
                                        name='min'
                                        type="datetime-local"
                                        defaultValue={moment(min).format("YYYY-MM-DDTkk:mm")}
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />                            
                                    <TextField
                                        className={classes.input_default}
                                        id="max"
                                        name='max'
                                        label={t('max')}
                                        type="datetime-local"
                                        defaultValue={moment(max).format("YYYY-MM-DDTkk:mm")}
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <Typography className={classes.title_default}>{t('vehicle')}</Typography>
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('license_plate')}</InputLabel>
                                            <Select
                                                labelId="vehicle"
                                                id="vehicle"
                                                value={uuid}
                                                name="uuid"
                                                onChange={this.handleChange}
                                            >
                                            {vehicles && vehicles.map(v =>
                                                <MenuItem value={v.uuid}>{v.license_plate}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <Typography className={classes.title_default}>{t('owner')}</Typography>
                                    <FormControl className={classes.input_default}>
                                    <InputLabel>{t('owner')}</InputLabel>
                                        <Input 
                                            type="text"
                                            name="owner" 
                                            value={owner} 
                                            onChange={this.handleChange}/>
                                            {submitted && !owner &&
                                                <Typography 
                                                    variant="caption" 
                                                    display="block" 
                                                    className="form-text text-muted">{t('required')}</Typography>
                                            }
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <Button 
                                        disabled={isLoading}
                                        variant="contained" 
                                        color="primary"
                                        type="submit"
                                        className={classes.button_default}
                                    >{isLoading && <LoadingSpinner/>}{t('save')}</Button>
                                    <Button
                                        className={classes.table_button_default}
                                        color="primary" 
                                        linkButton={true} 
                                        variant="contained" 
                                        component={Link} to={'.'}>
                                        {t('return')}
                                    </Button>
                                </div>
                            </form>
                        }
                        {alertMessage ?
                            <AlertAux message={alertMessage} type={alertType}></AlertAux>
                            : null
                        }   
                    </Grid>    
                </Grid>
                <Footer/>
            </Container>
        );
    }
}

RalsCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};


const styledRalsCreate = withStyles(Styles)(RalsCreate)
const translatedRalsCreate = withTranslation()(styledRalsCreate);
export { translatedRalsCreate as RalsCreate };