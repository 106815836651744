const config = {
    
    API_EHOOK: 'https://api.ehook.nossamae.com',
    API_COLDCHAIN: 'https://api.coldchain.nossamae.com',

    LOGIN: '/login',
    
    //Vehicle
    VEHICLE: '/vehicle',

    //RAL
    RAL: '/ral',
    DELIBERATE: '/deliberate',
    SHARE: '/use',
    USE: '/use',

    //password
    PASSWORD: '/counterpassword',

    //companies
    COMPANIES: '/company',
    CHILDRENSANDPARENT: '/company',
    CREATECOMPANY: '/company',
    UPDATECOMPANY: '/company',

    //users
    USERS: '/user'
};

export default config;