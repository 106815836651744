import { styleConstants } from './constants/style_constants'

export const Styles = theme => ({
    root: {
        flexGrow: 1,
    },
    navbar: {
        background: styleConstants.color_primary,
    },
    navbar_container: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    navbar_divisor: {
        width: 1,
        height: 30,
        backgroundColor: '#FFF',
    },
    navbar_icons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 100
    },
    navbar_text: {
        fontFamily: styleConstants.font_primary,
    },
    button_default : {
        fontFamily: styleConstants.font_primary,
        margin: '10px',
        color: '#FFF',
        background: styleConstants.color_primary,
        "&:hover": {
            backgroundColor: styleConstants.color_primary,
            opacity: .7
        }
    },
    button_outlined : {
        fontFamily: styleConstants.font_primary,
        margin: '10px',
        color: styleConstants.color_primary,
        "&:hover": {
            color: styleConstants.color_primary,
            opacity: .7
        }
    },
    icon_default : {
        fontFamily: styleConstants.font_primary,
        color: styleConstants.color_primary,
        "&:hover": {
            color: styleConstants.color_primary,
            opacity: .7
        }
    },
    icon_red_default : {
        fontFamily: styleConstants.font_primary,
        color: styleConstants.red,
        "&:hover": {
            color: styleConstants.red,
            opacity: .7
        }
    },
    button_red_default : {
        fontFamily: styleConstants.font_primary,
        margin: '10px',
        background: styleConstants.red,
        "&:hover": {
            backgroundColor: styleConstants.red,
            opacity: .7
        }
    },
    button_green_default : {
        fontFamily: styleConstants.font_primary,
        margin: '10px',
        background: styleConstants.green,
        "&:hover": {
            backgroundColor: styleConstants.green,
            opacity: .7
        }
    },
    button_yellow_default : {
        fontFamily: styleConstants.font_primary,
        marginRight: '10px',
        background: styleConstants.yellow,
        color: styleConstants.color_secundary,
        "&:hover": {
            backgroundColor: styleConstants.yellow,
            color: styleConstants.color_secundary,
            opacity: .7
        }
    },
    button_edit_user : {
        fontFamily: styleConstants.font_primary,
        fontSize: '12px',
        marginBottom: '10px',
        marginLeft: '10px',
        color: '#FFF',
        borderRadius: 0,
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        background: styleConstants.color_primary,
        transition: '1s',
        "&:hover": {
            backgroundColor: styleConstants.color_primary,
            opacity: .7
        }
    },
    input_default : {
        margin: '10px',
    },
    title_container : {
        backgroundColor: styleConstants.color_secundary,
    },
    yellow_row : {
        fontFamily: styleConstants.font_primary,
        background: styleConstants.yellow_low_op,
    },
    red_row : {
        fontFamily: styleConstants.font_primary,
        background: styleConstants.red_low_op,
    },
    green_row : {
        fontFamily: styleConstants.font_primary,
        background: styleConstants.green_low_op,
    },
    title_default : {
        fontFamily: styleConstants.font_primary,
        color: 'white',
        margin: '10px'
    },
    sec_title_default : {
        fontFamily: styleConstants.font_primary,
        color: styleConstants.color_secundary,
        margin: '10px'
    },
    table_default : {
        margin: '30px'
    },
    table_button_default: {
        fontFamily: styleConstants.font_primary,
        marginRight: '10px',
        background: styleConstants.color_primary,
        "&:hover": {
            backgroundColor: styleConstants.color_primary,
            opacity: .7
        }
    },
    passContainer: {
        margin: '10px',
        padding: '5px',
        borderStyle: 'solid',
        borderWidth: '1px'
    },
    pass: {
        fontWeight: 'bold'
    },
    company_row_cel: {
        backgroundColor: styleConstants.color_primary,
        color: '#FFF',
    },
    company_row_cel_edit: {
        color: '#FFF',
    },
    checked: {
        color: styleConstants.green
    },
    un_checked: {
        color: styleConstants.red
    },
    select_owner: {
        width: '200px',
    }
});