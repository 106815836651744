import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner, AlertAux, Footer } from '../../components';
import { vehicleService, deviceService } from '../../services';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';


class VehiclesCreateUpdate extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {      
            isAddMode: !this.props.match.params.id,
            id: this.props.match.params,
            isLoading: true,
            submitted: false,
            alertMessage: null,
            alertType: '',
            uuid: '',
            license_plate: '',
            type: 'truck',
            city: '',
            state: 'SP',
            country: 'BR',
            company_identifier: '',
            device_list: [],
            notAvailableDevicesOBUID: [],
            devices: [],
            obuid: 'empty'
        };  
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    createVehicle(state) {

        const createData = {
            license_plate: state.license_plate,
            type: state.type,
            city: state.city,
            state: state.state,
            country: state.country,
            device_list: []
        }

        return vehicleService.create(createData)
            .then(v => {
                this.setState(
                {
                    alertMessage: this.props.t('create_success'), 
                    alertType: 'success',
                    isLoading: false,
                    submitted: false,
                })
            })
            .catch(v => {          
                this.setState({
                    alertMessage: v, 
                    alertType: 'error',
                    isLoading: false,
                    submitted: false,
                })     
            });
    }

    updateVehicle(state) {
        
        const device_list = []
        if(state.obuid !== 'empty'){
            const device = state.devices.filter(obj => {
                return obj.obuid === parseInt(state.obuid)
            }) 
            device_list.push(device[0]) 
        }

        const createData = {
            uuid: state.uuid,
            license_plate: state.license_plate,
            type: state.type,
            city: state.city,
            state: state.state,
            country: state.country,
            device_list: device_list
        }

        return vehicleService.update(createData)
            .then(() => {
                this.setState({
                    alertMessage: this.props.t('update_success'), 
                    alertType: 'success',
                    isLoading: false,
                    submitted: false,
                })
            })
            .catch(v => {
                this.setState({
                    alertMessage: v, 
                    alertType: 'error',
                    isLoading: false,
                    submitted: false,
                })
            });
    }


    handleChange (event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit (event) {
        event.preventDefault();
        
        this.setState({ submitted: true})
        const {
            uuid,
            license_plate,
            type,
            city,
            state,
            country
        } = this.state;

        var companyAux = this.state
        if(this.state.isAddMode){
            const { user } = this.props;
            companyAux = user.company_identifier
        }
        const company_identifier = companyAux;

        if( (uuid || this.state.isAddMode) &&
            license_plate &&
            type &&
            city &&
            state &&
            country &&
            company_identifier)
        {
            this.setState({ isLoading: true});
            this.state.isAddMode
            ? this.createVehicle(this.state)
            : this.updateVehicle(this.state);
        }
    }

    componentDidMount() {

        if (!this.state.isAddMode) {
            // get vehicle and set form fields
            vehicleService.getOne(this.state.id).then(v => {
                if(v.length === 1) {
                    this.setState({
                        uuid: v[0].uuid,
                        license_plate: v[0].license_plate,
                        type: v[0].type,
                        city: v[0].city,
                        state: v[0].state,
                        country: v[0].country,
                        company_identifier: v[0].company_identifier,
                        device_list: v[0].device_list
                    }) 
                }

                if(v[0].device_list.length > 0) {
                    this.setState({
                        obuid: v[0].device_list[0].obuid
                    })
                }

                vehicleService.list()
                .then(vehicles => {
                    var notAvailableDevicesOBUIDAux = []
                    
                    for (var i = 0; i < vehicles.length; i++) {
                        for (var j = 0; j < vehicles[i].device_list.length; j++) {
                            if(
                                v[0].device_list.length > 0 &&
                                vehicles[i].device_list[j].obuid === v[0].device_list[0].obuid
                            )
                            {continue}
                            else {
                                notAvailableDevicesOBUIDAux.push(parseInt(vehicles[i].device_list[j].obuid))
                            }
                        }
                    }
                    this.setState({notAvailableDevicesOBUID: notAvailableDevicesOBUIDAux})

                    deviceService.list().then(v => {
                        
                        var devices = v.filter(function(item) {
                            
                            if(process.env.REACT_APP_ACCEPT_EHOOK === "1"
                                && process.env.REACT_APP_ACCEPT_SSL === "0"
                            )
                            {
                                return (
                                    item.type === "ehook" && 
                                    (
                                        !notAvailableDevicesOBUIDAux.includes(item.obuid)
                                        || item.obuid === 1 // empty select
                                    )
                                    );
                            }
                            else if(process.env.REACT_APP_ACCEPT_EHOOK === "0"
                                && process.env.REACT_APP_ACCEPT_SSL === "1"
                            )
                            {
                                return (
                                    item.type === "ssl" && 
                                    (
                                        !notAvailableDevicesOBUIDAux.includes(item.obuid)
                                        || item.obuid === 1 // empty select
                                    )
                                    );
                            }
                            else if(process.env.REACT_APP_ACCEPT_EHOOK === "1"
                                && process.env.REACT_APP_ACCEPT_SSL === "1"
                            )
                            {
                                return (
                                    (item.type === "ssl" || item.type === "ehook") && 
                                    (
                                        !notAvailableDevicesOBUIDAux.includes(item.obuid)
                                        || item.obuid === 1 // empty select
                                    )
                                    );
                            }
                            else return (item.obuid === 1);

                        });
                        this.setState({
                            devices: devices,
                            isLoading: false
                        })
                    }).catch(v => {
                        this.setState({
                            alertMessage: v, 
                            alertType: 'error',
                            isLoading: false,
                            submitted: false,
                        })
                    });

                })
                .catch(v => {
                    this.setState({
                        alertMessage: v, 
                        alertType: 'error',
                        isLoading: false,
                        submitted: false,
                    })
                });

            });
        }
        else{
            this.setState({ isLoading: false}) 
        }
    }

    render(){
        const isAddMode = this.state.isAddMode;
        const { t } = this.props;
        const {
            uuid,
            license_plate,
            type,
            city,
            state,
            country,
            devices,
            obuid
        } = this.state;
        const isLoading = this.state.isLoading;
        const submitted = this.state.submitted;
        const { alertMessage, alertType } = this.state;
        const { classes } = this.props

        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.title_container}>
                        <Typography 
                            variant="h3" 
                            component="h1" 
                            gutterBottom
                            className={classes.title_default}
                        >{isAddMode ? (t('add')+" "+t('vehicle')) : (t('edit')+" "+t("vehicle"))}
                        </Typography>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={6} spacing={0}>
                        {isLoading ?
                            <LoadingSpinner></LoadingSpinner>
                        :
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                {!isAddMode &&
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('id')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="uuid" 
                                            disabled={true}
                                            value={uuid} 
                                            onChange={this.handleChange}/>
                                    </FormControl>
                                </div>
                                }
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('license_plate')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="license_plate"
                                            disabled={!isAddMode}          
                                            value={license_plate} 
                                            onChange={this.handleChange}/>
                                        {submitted && !license_plate &&
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                className="form-text text-muted">{t('required')}</Typography>
                                        }
                                    </FormControl>
                                </div>   
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('type')}</InputLabel>
                                            <Select
                                                labelId="type"
                                                id="type"
                                                value={type}
                                                name="type"
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value='truck'>{t('truck')}</MenuItem>
                                                <MenuItem value='trailer'>{t('trailer')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {!isAddMode &&
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('device')}</InputLabel>
                                            <Select
                                                labelId="device"
                                                id="device"
                                                value={obuid}
                                                name="obuid"
                                                onChange={this.handleChange}
                                            >
                                            {devices && devices.map(v =>
                                                <MenuItem value={v.obuid}>OBUID: {v.obuid} - Alias: {v.alias}</MenuItem>
                                            )}
                                                <MenuItem value={'empty'}>{t('no_devices')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>  
                                }                     
                            </div>
                            {/* <div className="form-row">
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('company')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="company_identifier" 
                                            value={company_identifier} 
                                            onChange={this.handleChange}/>
                                        {submitted && !company_identifier &&
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                className="form-text text-muted">{t('required')}</Typography>
                                        }
                                    </FormControl>
                                </div>     
                            </div> */}
                            <div className="form-row">
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('city')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="city" 
                                            value={city} 
                                            onChange={this.handleChange}/>
                                        {submitted && !city &&
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                className="form-text text-muted">{t('required')}</Typography>
                                        }
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('state')}</InputLabel>
                                            <Select
                                                labelId="state"
                                                id="state"
                                                value={state}
                                                name="state"
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value='SP'>São Paulo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>   
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('country')}</InputLabel>
                                            <Select
                                                labelId="country"
                                                id="country"
                                                value={country}
                                                name="country"
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value='BR'>Brazil</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>  
                            </div>
                            <div className="form-group">
                                <Button 
                                    disabled={isLoading}
                                    variant="contained" 
                                    color="primary"
                                    type="submit"
                                    className={classes.button_default}
                                >{isLoading && <LoadingSpinner/>}{t('save')}</Button>
                                <Button
                                    className={classes.button_default}
                                    color="primary" 
                                    linkButton={true} 
                                    variant="contained" 
                                    component={Link} to={isAddMode ? '.' : '..'}>
                                    {t('return')}
                                </Button>
                            </div>        
                        </form>
                        }                                                
                        {alertMessage ?
                            <AlertAux message={alertMessage} type={alertType}></AlertAux>
                            : null
                        }   
                    </Grid>
                </Grid>
                <Footer/>
            </Container>
        );
    }
}

VehiclesCreateUpdate.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const actionCreators = {

}

const connectedVehiclesCreateUpdate = connect(mapState, actionCreators)(VehiclesCreateUpdate);
const translatedVehiclesCreateUpdate = withTranslation()(connectedVehiclesCreateUpdate);
const styledVehiclesCreateUpdate = withStyles(Styles)(translatedVehiclesCreateUpdate)
export { styledVehiclesCreateUpdate as VehiclesCreateUpdate };