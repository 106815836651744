import React from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { alertActions } from './actions';
import { PrivateRoute, AlertAux, AdminPermissions } from './components';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { VehiclesPage, VehiclesCreateUpdate } from './pages/VehiclesPage';
import { RalsCreate, RalsPage } from './pages/RALS';
import { CompaniesPage, CompanyCreate } from './pages/CompaniesPage';
import { DevicesPage } from './pages/DevicesPage';
import { UsersPage } from './pages/UsersPage';
import Container from '@material-ui/core/Container';
import { styleConstants } from './constants/style_constants'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
import { UserCreate } from './pages/UsersPage/UserCreate';

class App extends React.Component {

    componentDidMount(){
        const favicon = document.getElementById("favicon");
        favicon.href = styleConstants.logo1;
        document.title = process.env.REACT_APP_PAGE_TITLE
    }

    render() {

        const { alert } = this.props;
        
        return (
            <Container maxWidth="xl">
                <ToastContainer />
                <div className="">                  
                    <Switch>
                        <PrivateRoute exact path="/" component={HomePage} />
                        <PrivateRoute exact path="/vehicles" component={VehiclesPage} />
                        <PrivateRoute exact path="/rals" component={RalsPage} />
                        <PrivateRoute exact path="/companies" component={CompaniesPage}>
                            <AdminPermissions exact path="/companies" component={CompaniesPage} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/companies/add" component={CompanyCreate}>
                            <AdminPermissions exact path="/companies/add" component={CompanyCreate} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/users/add" component={UserCreate}>
                            <AdminPermissions exact path="/users/add" component={UserCreate} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/devices" component={DevicesPage}>
                            <AdminPermissions exact path="/devices" component={DevicesPage} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/users" component={UsersPage} />
                        <PrivateRoute path="/rals/add" component={RalsCreate} />
                        <PrivateRoute path="/vehicles/add" component={VehiclesCreateUpdate} />
                        <PrivateRoute path="/vehicles/edit/:id" component={VehiclesCreateUpdate} />
                        <Route path="/login" component={LoginPage} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
                {alert.message &&
                    <AlertAux message={alert.message} type={alert.type}></AlertAux>
                }
            </Container>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
const translatedApp = withTranslation()(connectedApp);
export { translatedApp as App };