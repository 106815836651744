import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../components';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { vehicleService } from '../../services'
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

class VehiclesRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            open: false,
            isDeleting: false,
            loading: false,
            confirmationDialog: false,
            passwordDialog: false,
            pass: '',
            hashUser: '',
            cpass: null,
            generated: false,
            incorrectPassword: true,
            incorrectHash: true,
            error: false,
            userRole: '',
        };
        this.deleteVehicle = this.deleteVehicle.bind(this);
        this.getPassword = this.getPassword.bind(this);
        this.generateCounterPassword = this.generateCounterPassword.bind(this);
        this.handleChangeHashUser = this.handleChangeHashUser.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    componentDidMount(){
        const role = this.props.user.roles[0];
        this.setState((oldState) => {
            return {
                ...oldState,
                userRole: role,
            }
        });
    }

    getPassword() {
        this.setState(oldState => {
            return {
                ...oldState,
                passwordDialog: true,
            }
        });
        this.setState({loading: true})
    }

    async handleChangeHashUser(e) {
        const { value } = e.target;
        await this.setState({ hashUser: value.substring(0, 7) });
        const { hashUser:hash } = this.state

        if (!hash || hash.length !== 7) {
            this.setState(oldState => (
                {
                    ...oldState,
                    loading: false,
                    incorrectHash: true,
                }
            ))
        } else {
            this.setState(oldState => (
                {
                    ...oldState,
                    loading: false,
                    incorrectHash: false,
                }
            ))
        }
    }

    async handleChangePassword(e) {
        const { value } = e.target;
        await this.setState({ pass: value.substring(0, 6) });
        const { pass:password } = this.state


        if (!password || password.length !== 6) {
            this.setState(oldState => (
                {
                    ...oldState,
                    loading: false,
                    incorrectPassword: true,
                }
            ))
        } else {
            this.setState(oldState => (
                {
                    ...oldState,
                    loading: false,
                    incorrectPassword: false,
                }
            ))
        }
    }

    generateCounterPassword(obuid) {
        const { pass:password, hashUser:hash } = this.state
        // const { user } = this.props;

        //USER_HASH - Primeiros 7 dígitos do sha256 do username do usuário (SUBSTR	(0, 7, sha256 ( username ) ) )
        // var userHash = sha256(user.user).substring(0, 7);
        if (password.length !== 6 || hash.length !== 7) {
            return
        }

        vehicleService.getPassword(hash, obuid, password).then(pass => {
            this.setState({
                incorrectPassword: false,
                incorrectHash: false,
                generated: true,
                error: pass.error,
                loading: false,
                pass: '',
                hashUser: '',
                cpass: pass.counterpass
            })
        }).catch(()=>{
            this.setState({
                passwordDialog: false,
                generated: false,
                loading: false,
                cpass: null
            })
        });
    }

    deleteVehicle(uuid) {
        this.setState({confirmationDialog: false})
        this.setState({isDeleting: true})
        vehicleService._delete(uuid).then(() => {
            this.setState({isDeleting: false})
            this.props.handlerUpdate()
        }).catch(()=>{
            this.setState({isDeleting: false})
            this.props.handlerUpdate()
        });
    }

    render() {
        const path = this.props.props.match.path
        const vehicle = this.props.vehicle;
        const { t } = this.props;
        const { 
            open,
            confirmationDialog,
            passwordDialog,
            pass,
            cpass,
            incorrectHash,
            incorrectPassword,
            generated,
            error,
            hashUser,
            userRole
        } = this.state;
        const { classes } = this.props;
        // vehicle
        /*
        {
            "uuid": "88f56636-04d3-11eb-941d-02420a002d08",
            "license_plate": "AAA0001",
            "type": "truck",
            "city": "São Paulo",
            "state": "SP",
            "country": "BR",
            "company_identifier": "65575",
            "device_list": [
                {
                    "type": "ssl",
                    "obuid": "1870624410",
                    "alias": "000D6F7F769A"
                }
            ]
        },
        */
        return (
            <React.Fragment>
                <TableRow key={vehicle.uuid}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({open : !open})}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {vehicle.uuid}
                    </TableCell>
                    <TableCell align="right">{vehicle.license_plate}</TableCell>
                    <TableCell align="right">{vehicle.type === 'truck' ? 
                                t('truck') 
                                : vehicle.type === 'trailer' ? 
                                t('trailer') : vehicle.type}
                    </TableCell>
                    <TableCell align="right">{vehicle.company_identifier}</TableCell>
                    <TableCell align="right">{vehicle.city+"/"+vehicle.state+" "+vehicle.country}</TableCell>
                    <TableCell>
                            <IconButton
                                color="inherit"
                                component={Link} to={`${path}/edit/${vehicle.uuid}`}
                                className={classes.icon_default}
                            >
                                <EditIcon />
                            </IconButton>
                            {
                                vehicle.device_list[0] && userRole === 'SSL-ADMIN' &&
                                <IconButton
                                    className={classes.icon_default} 
                                    variant="contained"
                                    onClick={() => this.getPassword(vehicle.device_list[0].obuid)} 
                                    >
                                    {this.state.loading 
                                        ? <LoadingSpinner></LoadingSpinner>
                                        : <LockIcon/>
                                    }
                                </IconButton>
                            }
                            <IconButton
                                onClick={() => this.setState({confirmationDialog: true})}
                                linkButton={true} 
                                variant="contained"
                                className={classes.icon_red_default}
                                >
                                {this.state.isDeleting 
                                    ? <LoadingSpinner></LoadingSpinner>
                                    : <DeleteIcon/>
                                }
                            </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Paper elevation={3}>
                        <Typography className={classes.sec_title_default} variant="h6" gutterBottom component="div">
                            {t('devices')}
                        </Typography>
                        {vehicle.device_list.length > 0 ? 
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                <TableRow>
                                    <TableCell>{t('obuid')}</TableCell>
                                    <TableCell align="right">{t('type')}</TableCell>
                                    <TableCell align="right">{t('device')}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {vehicle.device_list.map((device) => (
                                    <TableRow key={device.obuid}>
                                        <TableCell component="th" scope="row">
                                            {device.obuid}
                                        </TableCell>
                                        <TableCell align="right">{device.type}</TableCell>
                                        <TableCell align="right">{device.alias}</TableCell>
                                        </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        :
                                <Typography className={classes.sec_title_default}>{t('no_devices')}</Typography>
                        }

                        </Paper>
                    </Collapse>
                </TableCell>
            </TableRow>
            {vehicle.device_list[0] && 
                    <Dialog
                        onClose={() => this.setState({passwordDialog: false, loading: false})} 
                        aria-labelledby="simple-dialog-title" 
                        open={passwordDialog}
                        fullWidth='sm'
                        maxWidth='sm'>
                        <DialogTitle 
                            id="simple-dialog-title"
                            className={classes.sec_title_default}
                        >{t('temporary_pass_counterpass')}
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center">
                                <div>
                                    <DialogContentText className={classes.sec_title_default}>
                                        <Typography gutterBottom>{t('license_plate')} : {vehicle.license_plate}</Typography>
                                        <Typography gutterBottom>{t('obuid')} : {vehicle.device_list[0].obuid}</Typography>
                                        <Typography gutterBottom>{t('alias')} : {vehicle.device_list[0].alias}</Typography>
                                    </DialogContentText>
                                    <form onSubmit={this.handleSubmit}>
                                        <div>
                                            <FormControl>
                                                <InputLabel>{t('hashOfUser')}</InputLabel>
                                                <Input 
                                                    type="text" 
                                                    value={ hashUser }
                                                    onChange={this.handleChangeHashUser}/>
                                            </FormControl>
                                            {   incorrectHash &&
                                                <Typography 
                                                    variant="caption" 
                                                    display="block" 
                                                    className="form-text text-muted">{t('userHashRequirements')}</Typography>
                                            }
                                        </div>
                                        <div>
                                            <FormControl>
                                                <InputLabel>{t('password')}</InputLabel>
                                                <Input
                                                    type="text"
                                                    value={ pass }
                                                    onChange={this.handleChangePassword}/>
                                            </FormControl>
                                            {
                                                incorrectPassword &&
                                                <Typography variant="caption" display="block" className="form-text text-muted">{t('passwordRequirements')}</Typography>
                                            }
                                        </div>
                                        <div>
                                        <Button
                                            className={classes.button_default}
                                            color="primary"
                                            variant="contained"
                                            onClick={ () => this.generateCounterPassword(vehicle.device_list[0].obuid) }
                                        >
                                            { t('generate_counterpass') }
                                        </Button>
                                        </div>
                                    </form>
                                    {
                                        error &&
                                        <Typography 
                                            variant="caption" 
                                            display="block" 
                                            className="form-text text-muted">{t('wrongPassword')}
                                        </Typography>
                                    }
                                </div>
                                <div style={{width: '40%'}}>
                                    {
                                        generated && !error &&
                                        <div>
                                            <Typography>
                                                {t('counterpass')}:
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                className={classes.pass}
                                                style={{fontSize: 50, color: '#0994dd'}}
                                            >
                                                {cpass}
                                            </Typography>
                                        </div>
                                        
                                    } 
                                </div>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={() => this.setState({passwordDialog: false, loading: false})} 
                                color="primary" 
                                autoFocus
                                className={classes.button_outlined}
                                >
                                {t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>                
                }

            <Dialog 
                    onClose={() => this.setState({confirmationDialog: false})} 
                    aria-labelledby="simple-dialog-title" 
                    open={confirmationDialog}
                    fullWidth='sm'
                    maxWidth='sm'
                >
                    <DialogTitle 
                        id="simple-dialog-title"
                        className={classes.sec_title_default}
                    >{t('confirm_delete')}
                    </DialogTitle>
                    <DialogActions>
                        <Button 
                            onClick={() => this.deleteVehicle(vehicle.uuid)} 
                            color="primary"
                            variant="contained" 
                            className={classes.button_green_default}
                            >
                            {t('yes')}
                        </Button>
                        <Button 
                            onClick={() => this.setState({confirmationDialog: false})}
                            color="primary" 
                            variant="contained" 
                            autoFocus    
                            className={classes.button_red_default}
                            >
                            {t('no')}
                        </Button>
                    </DialogActions>
                </Dialog>
        </React.Fragment>
        );
    }
}

VehiclesRows.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedVehiclesRows = connect(mapState)(VehiclesRows);
const translatedVehiclesRows = withTranslation()(connectedVehiclesRows);
const styledVehiclesRows = withStyles(Styles)(translatedVehiclesRows)
export { styledVehiclesRows as VehiclesRows };