import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../actions';
import { LoadingSpinner } from '../../components';
import { withTranslation } from 'react-i18next';

import { styleConstants } from '../../constants/style_constants'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider'
import { Styles } from '../../styles'
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import i18n from '../../i18n';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            anchorEl: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    handleMenu(event){
        this.setState({anchorEl: event.currentTarget})
    };
    
    handleClose(){
        this.setState({anchorEl: null})
    };

    changeLanguage (lng) {
        i18n.changeLanguage(lng);
        this.handleClose()
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { loggedIn } = this.props;
        const { t } = this.props;
        const { username, password, submitted } = this.state;
        const { anchorEl} = this.state;
        const { classes } = this.props;
        const open = Boolean(anchorEl);

        if(loggedIn){
            return <Redirect></Redirect>
        }
        return (
            <Grid container className={classes.root} spacing={3}>
                <Grid item xs={12} className={classes.title_container}>
                    <Typography 
                        variant="h2" 
                        component="h1" 
                        gutterBottom
                        className={classes.title_default}
                        >{t('ssl_panel')}</Typography>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={6}>
                    <Typography 
                        variant="h3" 
                        component="h1" 
                        gutterBottom
                        className={classes.sec_title_default}
                    >{t('login')}</Typography>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                            <FormControl className={classes.input_default}>
                                <InputLabel htmlFor="username">{t('username')}</InputLabel>
                                <Input 
                                    type="text" 
                                    name="username" 
                                    value={username} 
                                    onChange={this.handleChange}/>
                            </FormControl>
                            {submitted && !username &&
                                <Typography 
                                    variant="caption" 
                                    display="block" 
                                    className="form-text text-muted">{t('required')}</Typography>
                            }
                        </div>
                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <FormControl className={classes.input_default}>
                                <InputLabel htmlFor="password">{t('password')}</InputLabel>
                                <Input 
                                    type="password" 
                                    name="password" 
                                    value={password} 
                                    onChange={this.handleChange}/>
                            </FormControl>
                            {submitted && !username &&
                                <Typography variant="caption" display="block" className="form-text text-muted">{t('required')}</Typography>
                            }
                        </div>
                        <div className="form-group">
                            <Button 
                                variant="contained" 
                                color="primary"
                                type="submit"
                                className={classes.button_default}
                                >{t('login')}</Button>
                            {loggingIn &&
                                <LoadingSpinner></LoadingSpinner>   
                            }
                        </div>
                    </form>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                    >
                        <LanguageIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={() => {this.changeLanguage('pt')}}>PT-BR</MenuItem>
                        <MenuItem onClick={() => {this.changeLanguage('en')}}>EN-US</MenuItem>
                    </Menu>
                </Grid>
                <Grid item xs={6}>
                    <img src={styleConstants.logo1} width="500" alt="logo"/>
                </Grid>
            </Grid>
        );
    }
}

function mapState(state) {
    const { loggingIn, loggedIn } = state.authentication;
    return { loggingIn, loggedIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
const translatedLoginPage = withTranslation()(connectedLoginPage);
const styledLoginPage = withStyles(Styles)(translatedLoginPage)
export { styledLoginPage as LoginPage };