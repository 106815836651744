import React from 'react';
import { LoadingSpinner, AlertAux } from '../../components';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { timeConverter } from '../../helpers'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { ralService } from '../../services'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

class RalsRows extends React.Component {

    constructor(props) {
        super(props);        
        this.state = {      
            open: false,
            isDeleting: false,
            isLoading: false,
            deliberationDialog: false,
            confirmationDialog: false,
            alertMessage: '', 
            alertType: '', 
            uses: []
        };  
        this.deleteRal = this.deleteRal.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.dialogClose = this.dialogClose.bind(this);      
    }

    loadRalUses() {
        ralService.usesList(this.props.ral.uuid)
        .then(uses => {
            this.setState({uses: uses})  
        }).catch(v => {          
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',
                isLoading: false
            })     
        });
    }

    componentWillReceiveProps() {
        this.setState({open: false})
    }

    componentDidMount(){
        this.loadRalUses()                
    }

    dialogOpen(){
        this.setState({deliberationDialog: true})
    }

    dialogClose(){
        this.setState({deliberationDialog: false})
    }

    deliberate(uuid, deliberation_status){
        
        const data = {
            uuid: uuid,
            deliberation_status: deliberation_status
        }

        this.setState({isLoading: true})

        return ralService.deliberate(data)
            .then(v => {
                this.setState({                  
                    deliberationDialog: false,
                    isLoading: false
                })
                this.props.handlerUpdate()
            })
            .catch(v => {          
                this.setState({
                    alertMessage: v, 
                    alertType: 'error',
                    isLoading: false
                })     
            });

    }

    use(uuid,latitude,longitude){
        const data = {
            uuid: uuid,
            latitude: latitude,
            longitude: longitude,
            timestamp: (new Date().getTime())/1000,
            geo_accuracy: 1.0
        }

        this.setState({isLoading: true})

        return ralService.use(data)
            .then(v => {
                this.setState({                  
                    isLoading: false
                })
                this.props.handlerUpdate()
            })
            .catch(v => {          
                this.setState({
                    alertMessage: v, 
                    alertType: 'error',
                    isLoading: false
                })     
            });
    }

    deleteRal(uuid) {
        this.setState({confirmationDialog: false})
        this.setState({isDeleting: true})
        ralService._delete(uuid).then(() => {
            this.setState({isDeleting: false})
            this.props.handlerUpdate()
        }).catch(()=>{
            this.setState({isDeleting: false})
            this.props.handlerUpdate()
        });
        
    }

    rowState(deliberation_status){
        const { classes } = this.props;
        if(deliberation_status === "pending")
            return classes.yellow_row
        if(deliberation_status === "authorized")
            return classes.green_row
        if(deliberation_status === "rejected")
            return classes.red_row
    }

    render() {
        //const path = this.props.props.match.path
        const ral = this.props.ral;
        const { t } = this.props;
        const { uses } = this.state;
        const { deliberationDialog } = this.state;
        const { alertMessage, alertType, isLoading, open, confirmationDialog } = this.state;
        const { classes } = this.props;

        
        return (
            <React.Fragment>
                <TableRow key={ral.uuid}
                    className={this.rowState(ral.deliberation_status)}
                >
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" 
                        onClick={() => {
                            this.setState({open : !open})
                            if (!open) this.loadRalUses()
                        }}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{ral.uuid}</TableCell>
                    <TableCell align="right">{ral.deliberated_by}</TableCell>
                    <TableCell align="right">
                        {ral.deliberation_status === 'authorized' ? t('authorized') :
                            ral.deliberation_status === 'pending' ? t('pending') :
                                ral.deliberation_status === 'rejected' ? t('rejected') :
                                ral.deliberation_status
                        }
                    </TableCell>
                    <TableCell align="right">{ral.vehicle.license_plate}</TableCell>
                    <TableCell align="right">
                        <a href={`http://www.google.com/maps/place/${ral.latitude},${ral.longitude}`}
                        >{ral.latitude},{ral.longitude}</a>
                    </TableCell>
                    <TableCell align="right">{ral.radius}</TableCell>
                    <TableCell align="right">{ral.owner}</TableCell>
                    <TableCell align="right">{timeConverter(ral.timestamp_created)}</TableCell>
                    <TableCell align="right">
                        {
                            ral.timestamp_deliberated ? timeConverter(ral.timestamp_deliberated)
                            : ""
                        }
                    </TableCell>
                    <TableCell align="right">{timeConverter(ral.timestamp_min)+" - "+timeConverter(ral.timestamp_max)}</TableCell>
                    <TableCell align="right">{}</TableCell>
                    
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {ral.deliberation_status === 'pending' ?
                            <Button
                                size="small"
                                color="primary" 
                                variant="contained" 
                                onClick={this.dialogOpen}
                                className={classes.button_yellow_default}
                                >
                                {t('deliberate')}
                            </Button>
                        :
                            <div/>
                        }
                        {/* <Button
                                size="small"
                                color="primary" 
                                variant="contained" 
                                className={classes.table_button_default}
                                onClick={() => this.use(ral.uuid,ral.latitude,ral.longitude)}
                                >
                                {t('use')}
                        </Button> */}
                        <IconButton
                            //linkButton={true}
                            className={classes.icon_default} 
                            variant="contained" 
                            //component={Link} to={`${path}/edit/${ral.uuid}`}
                            >
                            <ShareIcon/>
                        </IconButton>
                        <IconButton
                            className={classes.icon_red_default}
                            onClick={() => this.setState({confirmationDialog: true})}
                            >
                            {this.state.isDeleting 
                                ? <LoadingSpinner></LoadingSpinner>
                                : <DeleteIcon/>
                            }
                        </IconButton>
                    </TableCell>
                </TableRow>

                <Box>
                    {alertMessage ?
                        <AlertAux message={alertMessage} type={alertType}></AlertAux>
                        : null
                    }   
                </Box>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Paper elevation={3} >
                                <Typography 
                                    className={classes.sec_title_default} 
                                    variant="h6" 
                                    gutterBottom 
                                    component="div">
                                    {t('uses')}
                                </Typography>
                                {uses.length > 0 ? 
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('id')}</TableCell>
                                                <TableCell align="right">{t('timestamp')}</TableCell>
                                                <TableCell align="right">{t('location')}</TableCell>
                                                <TableCell align="right">{t('geo_accuracy')}</TableCell>                                  
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {uses.map((use) => (
                                            <TableRow key={use.uuid}>
                                                <TableCell component="th" scope="row">
                                                    {use.uuid}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {timeConverter(use.timestamp)}
                                                </TableCell>
                                                <TableCell align="right"> 
                                                    <a href={`http://www.google.com/maps/place/${ral.latitude},${ral.longitude}`}
                                                    >{ral.latitude},{ral.longitude}</a></TableCell>
                                                <TableCell align="right">{use.geo_accuracy}</TableCell>
                                                </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                :
                                    <Typography className={classes.sec_title_default}>{t('no_uses')}</Typography>
                                }
                            </Paper>
                        </Collapse>
                    </TableCell>
                </TableRow>

                <Dialog 
                    onClose={this.dialogClose} 
                    aria-labelledby="simple-dialog-title" 
                    open={deliberationDialog}
                    fullWidth='sm'
                    maxWidth='sm'
                    >
                    <DialogTitle 
                        id="simple-dialog-title"
                        className={classes.sec_title_default}
                    >{t('deliberation')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.sec_title_default}>
                            <Typography gutterBottom>{t('ral')} : {ral.uuid}</Typography>
                            <Typography gutterBottom>{t('license_plate')} : {ral.vehicle.license_plate}</Typography>
                            <Typography gutterBottom>{t('location')} : <a href={`http://www.google.com/maps/place/${ral.latitude},${ral.longitude}`}
                                                >{ral.latitude},{ral.longitude}</a></Typography>
                            <Typography gutterBottom>{t('radius')} : {ral.radius}</Typography>
                            <Typography gutterBottom>{t('owner')} : {ral.owner}</Typography>
                            <Typography gutterBottom>{t('created')} : {timeConverter(ral.timestamp_created)}</Typography>
                            <Typography gutterBottom>{t('liberation_period')} : {timeConverter(ral.timestamp_min)+" - "+timeConverter(ral.timestamp_max)}</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={() => this.deliberate(ral.uuid,"authorized")} 
                            color="primary"
                            variant="contained"
                            disabled={isLoading} 
                            className={classes.button_green_default}
                            >
                            {t('authorize')}
                        </Button>
                        <Button 
                            onClick={() => this.deliberate(ral.uuid,"rejected")} 
                            color="primary" 
                            variant="contained" 
                            autoFocus
                            disabled={isLoading}
                            className={classes.button_red_default}
                            >
                            {t('deny')}
                        </Button>
                        <Button 
                            onClick={this.dialogClose} 
                            color="primary" 
                            autoFocus
                            disabled={isLoading}
                            className={classes.button_outlined}
                            >
                            {t('cancel')}
                        </Button>
                        {isLoading &&
                            <LoadingSpinner></LoadingSpinner>
                        }
                    </DialogActions>
                    {alertMessage ?
                        <AlertAux message={alertMessage} type={alertType}></AlertAux>
                        : null
                    }   
                </Dialog>

                <Dialog 
                    onClose={() => this.setState({confirmationDialog: false})} 
                    aria-labelledby="simple-dialog-title" 
                    open={confirmationDialog}
                    fullWidth='sm'
                    maxWidth='sm'
                >
                    <DialogTitle 
                        id="simple-dialog-title"
                        className={classes.sec_title_default}
                    >{t('confirm_delete')}
                    </DialogTitle>
                    <DialogActions>
                        <Button 
                            onClick={() => this.deleteRal(ral.uuid)} 
                            color="primary"
                            variant="contained" 
                            className={classes.button_green_default}
                            >
                            {t('yes')}
                        </Button>
                        <Button 
                            onClick={() => this.setState({confirmationDialog: false})}
                            color="primary" 
                            variant="contained" 
                            autoFocus    
                            className={classes.button_red_default}
                            >
                            {t('no')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }
}

RalsRows.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styledRalsRows = withStyles(Styles)(RalsRows)
const translatedRalsRows = withTranslation()(styledRalsRows);
export { translatedRalsRows as RalsRows };