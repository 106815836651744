
export const styleConstants = {
    red: 'rgba(255, 82, 25,1)',
    yellow: 'rgba(255, 252, 0,1)',
    green: 'rgba(0, 167, 41,1)',
    red_low_op: 'rgba(255, 82, 25,0.3)',
    yellow_low_op: 'rgba(255, 252, 0,0.3)',
    green_low_op: 'rgba(0, 167, 41,0.3)',
    color_primary: process.env.REACT_APP_PRIMARY_COLOR,
    color_secundary: process.env.REACT_APP_SECONDARY_COLOR,
    font_primary: 'Arial, Helvetica, sans-serif',
    logo1: require('../assets/images/'+process.env.REACT_APP_LOGO),
};