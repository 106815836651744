import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner, AlertAux, Footer } from '../../components';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider'
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { userService } from '../../services/user_service';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { companiesService } from '../../services/companies_service';

import Container from '@material-ui/core/Container';

class UserCreate extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {      
            isAddMode: !this.props.match.params.id,
            id: this.props.match.params,
            isLoading: true,
            submitted: false,
            alertMessage: null,
            company: '',
            companies: [],
            alertType: '',
            email: '',
            password: '',
            username: '',
        };  
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createUser = this.createUser.bind(this);
        this.generatePassword = this.generatePassword.bind(this);
        this.updateCompanies = this.updateCompanies.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    generatePassword() {
        var uppers = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowers = "abcdefghijklmnopqrstuvwxyz";
        var digits = "01234567890";
        var all = uppers + lowers + digits;
        var choices = [uppers,lowers,digits];
        var checks = [];

        var password = "";
        var ranLength = Math.ceil(Math.random()*10)+3;
        for(var i=0; i<ranLength; i++){
        var choice = choices[Math.ceil(Math.random()*3)-1];
        var choiceItem = choice[Math.ceil(Math.random()*(choice.length))-1]
        password += choiceItem;
        }

        for(var i=0; i<3; i++){ // Append needed values to end
        var choice = choices[i];
        var choiceItem = choice[Math.ceil(Math.random()*(choice.length))-1]
        password += choiceItem;
        }

        password = password.split("").sort(function(){
        return 0.5 - Math.random();
        }).join("");
        return password;
    }

    validateEmail(email) 
    {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    createUser() {
        const {
            username,
            email,
        } = this.state;
        if (username.length < 5 || !this.validateEmail(email)) {
            this.setState({ isLoading: false});
            return;
        } else {
            const createData = {
                username: username,
                email: email,
                password: this.generatePassword(),
            }
            console.log(createData);
            return userService.create(createData)
                .then(v => {
                    this.setState(
                    {
                        alertMessage: this.props.t('create_success'), 
                        alertType: 'success',
                        isLoading: false,
                        submitted: false,
                    })
                })
                .catch(v => {          
                    this.setState({
                        alertMessage: v, 
                        alertType: 'error',
                        isLoading: false,
                        submitted: false,
                    })     
                });
        }
    }

    updateUser() {
    }


    handleChange (event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit (event) {
        event.preventDefault();
        
        this.setState({ submitted: true})
        const {
            username,
            email
        } = this.state;

        if(username || email || this.state.isAddMode)
        {
            this.setState({ isLoading: true});
            this.state.isAddMode
            ? this.createUser()
            : this.updateUser();
        }
    }

    updateCompanies() {
        companiesService.childrens_companies(this.props.user.company_identifier)
        .then(companies => {
            const newCompanies = [];
            companies.forEach(company => {
                if (company.active === '1') {
                    newCompanies.push(company);
                }
            })
            this.setState({companies: newCompanies, company: newCompanies[0].company_identifier})
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',
            })
        });
    }

    componentDidMount() {
        this.setState({ isLoading: false});
        this.updateCompanies();
    }

    render(){
        const isAddMode = this.state.isAddMode;
        const { t } = this.props;
        const {
            username,
            email,
            company,
            companies
        } = this.state;
        const isLoading = this.state.isLoading;
        const submitted = this.state.submitted;
        const { alertMessage, alertType } = this.state;
        const { classes } = this.props

        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.title_container}>
                        <Typography 
                            variant="h3" 
                            component="h1" 
                            gutterBottom
                            className={classes.title_default}
                        >{isAddMode ? (t('add')+" "+t('username')) : (t('edit')+" "+t("username"))}
                        </Typography>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={6} spacing={0}>
                        {isLoading ?
                            <LoadingSpinner></LoadingSpinner>
                        :
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('username')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="username" 
                                            value={username} 
                                            onChange={this.handleChange}/>
                                        {submitted && !username &&
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                className="form-text text-muted">{t('required')}</Typography>
                                        }
                                    </FormControl>
                                </div>        
                            </div>
                            
                            <div className="form-row">
                                <div className="form-group">
                                    <FormControl className={classes.input_default}>
                                        <InputLabel>{t('email')}</InputLabel>
                                        <Input 
                                            type="text" 
                                            name="email" 
                                            value={email} 
                                            onChange={this.handleChange}/>
                                        {submitted && !email &&
                                            <Typography 
                                                variant="caption" 
                                                display="block" 
                                                className="form-text text-muted">{t('required')}</Typography>
                                        }
                                    </FormControl>
                                </div>        
                            </div>
                            
                            <div className="form-group">
                                <FormControl className={classes.input_default}>
                                    <InputLabel>{t('company')}</InputLabel>
                                    <Select
                                        labelId=""
                                        id="company"
                                        value={company}
                                        name="company"
                                        onChange={this.handleChange}
                                    >
                                        {
                                            companies && companies.map(
                                                (item) => {
                                                    return <MenuItem key={ item.company_identifier } value={ item.company_identifier }>{ item.company_name }</MenuItem>
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="form-group">
                                <Button 
                                    disabled={isLoading}
                                    variant="contained" 
                                    color="primary"
                                    type="submit"
                                    className={classes.button_default}
                                >{isLoading && <LoadingSpinner/>}{t('save')}</Button>
                                <Button
                                    className={classes.button_default}
                                    color="primary" 
                                    linkButton={true} 
                                    variant="contained" 
                                    component={Link} to={isAddMode ? '.' : '..'}>
                                    {t('return')}
                                </Button>
                            </div>        
                        </form>
                        }                                                
                        {alertMessage ?
                            <AlertAux message={alertMessage} type={alertType}></AlertAux>
                            : null
                        }   
                    </Grid>
                </Grid>
                <Footer/>
            </Container>
        );
    }
}

UserCreate.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const actionCreators = {

}

const connectedUserCreate = connect(mapState, actionCreators)(UserCreate);
const translatedUserCreate = withTranslation()(connectedUserCreate);
const styledUserCreate = withStyles(Styles)(translatedUserCreate)
export { styledUserCreate as UserCreate };