import config from '../config';
import { handleResponse, authHeader } from '../helpers';

export const userService = {
    login,
    logout,
    list,
    create,
    block_unblock
};

// user
// {
//     uuid: 123456
//     company_identifier: 65582
//     roles: ["SSL-ADMIN"]
//     user: "vbl-ssl-test-admin"
//     email: "gabriel.dev.lop@gmail.com"
//     isActive: true
// }

function list() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.API_EHOOK}${config.USERS}`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function create(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    
    return fetch(`${config.API_EHOOK}${config.USERS}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function block_unblock(user) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch(`${config.API_EHOOK}${config.USERS}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: 
        { 
            "Content-Type": "application/json",
            "Accept": "application/vnd.coldchain.v2+json",
        },
        body: JSON.stringify({
            username: username,
            password: password,
        })
    };

    return fetch(`${config.API_EHOOK}${config.LOGIN}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function logout() {
    console.log("LOGOUT")
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

