import React from 'react';
import { styleConstants } from '../constants'

function Footer() {
    return (
        <footer style={{  
            width: '100%',
            height: '100px',
            marginTop: '10px',
            position: 'relative'}}>
            <img src={styleConstants.logo1} width="100" alt="logo"/>
        </footer>
    );
}

export { Footer };