import React from 'react';
import { connect } from 'react-redux';
import { Footer, Nav } from '../../components';
import { withTranslation } from 'react-i18next';

import {
    Paper,
    Grid,
    Typography,
    Box
} from '@material-ui/core';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';

class HomePage extends React.Component {
    
    componentDidMount() {
        console.log("HomePage")
    }

    render() {
        
        const { t } = this.props;
        const { user } = this.props;
        const { classes } = this.props;
    
        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Nav />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography 
                                    variant="h3" 
                                    className={classes.sec_title_default}
                                    >
                                    {t('hi')} {user.user}!
                                </Typography>
                                <Typography 
                                    variant="h6" 
                                    className={classes.sec_title_default}
                                    >
                                    {t('welcome')}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        );
    }
}

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const actionCreators = {

}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
const translatedHomePage = withTranslation()(connectedHomePage);
const styledHomePage = withStyles(Styles)(translatedHomePage)
export { styledHomePage as HomePage };