import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)

export const AdminPermissions = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const local = localStorage.getItem('user');

        if (!local) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        } else {
            const data = JSON.parse(local);
            return data.roles.includes('SSL-ADMIN') ?
            <Component {...props} /> :
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
    }} />
)