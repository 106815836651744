import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { alertActions } from '../../actions';
import { Footer, PrivateRoute } from '../../components';
import { connect } from 'react-redux';
import { Nav } from '../../components'
import { VehiclesList, VehiclesCreateUpdate } from './index'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

class VehiclesPage extends React.Component {

    render() {
        
        const { match, location } = this.props


        console.log(`${match.path}/add`)
        return (
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Nav />
                    </Grid>
                        <Switch>
                            <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
                            <PrivateRoute path={match.path} component={VehiclesList} />
                            <PrivateRoute path={`${match.path}/add`} component={VehiclesCreateUpdate} />
                            <PrivateRoute path={`${match.path}/edit/:id`} component={VehiclesCreateUpdate} /> 
                        </Switch>
                </Grid>
                <Footer/>
            </Container>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { authentication } = state;
    const { user } = authentication;
    return { alert,user };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(VehiclesPage);
export { connectedApp as VehiclesPage };