import config from '../config';
import { handleResponse, authHeader } from '../helpers';

// ral
/*
[
  {
    "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "timestamp_created": 0,
    "timestamp_deliberated": 0,
    "deliberation_status": "pending",
    "latitude": 0,
    "longitude": 0,
    "radius": 0,
    "timestamp_min": 0,
    "timestamp_max": 0,
    "owner": "string",
    "deliberated_by": "string",
    "vehicle": {
      "uuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "license_plate": "string",
      "type": "truck",
      "city": "string",
      "state": "string",
      "country": "string",
      "company_identifier": 0,
      "device_list": [
        {
          "type": "ssl",
          "obuid": 0,
          "alias": "001122334455"
        }
      ]
    },
    "signature": "3044022060090c1805dfcd81864dae4d67ed5479bf55e4630b75f04a3e90d615385ef37402201c3d6690cf9d2db4713d88157c04b4872b021c41023c51df4c8ccde4258eeb71",
    "shared_with": [
      {
        "company_identifier": 655351
      }
    ]
  }
]
*/

export const ralService = {
    list,
    create,
    _delete,
    deliberate,
    usesList,
    use
};

function list() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.API_EHOOK}${config.RAL}`, requestOptions)
        .then(handleResponse)
        .then(rals => {
            return rals;
        });
}

function usesList(data) {

  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };
  const encodedValue = encodeURIComponent(data)

  return fetch(`${config.API_EHOOK}${config.RAL}${config.USE}?uuid=${encodedValue}`, requestOptions)
      .then(handleResponse)
      .then(uses => {
          return uses;
      });
}

function create(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
  
    return fetch(`${config.API_EHOOK}${config.RAL}`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function use(data) {

  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
  };
  
  return fetch(`${config.API_EHOOK}${config.RAL}${config.USE}`, requestOptions)
      .then(handleResponse)
      .then(result => {
          return result;
      });
}

function deliberate(data) {

  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(data)
  };
  
  return fetch(`${config.API_EHOOK}${config.RAL}${config.DELIBERATE}`, requestOptions)
      .then(handleResponse)
      .then(result => {
          return result;
      });
}


// prefixed with underscored because delete is a reserved word in javascript
function _delete(uuid){
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
  };

  const encodedValue = encodeURIComponent(uuid)

  return fetch(`${config.API_EHOOK}${config.RAL}?uuid=${encodedValue}`, requestOptions)
      .then(handleResponse)
      .then(ral => {
          return ral;
      });
}