import { userService } from '../services'

export function handleResponse(response) {

    return response.text().then(text => {
        
        const data = text && JSON.parse(text);
        
        //console.log(data)
        
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }
            
            var errorMessage = '';
            if(data.code){
                errorMessage = errorMessage+data.code
            }
            if(data.message){
                errorMessage = errorMessage+" - "+data.message
            }
            if(data.customMessage){
                errorMessage = errorMessage+" - "+data.customMessage
            }
            const errorMessageSent = errorMessage;
            return Promise.reject(errorMessageSent);
        }

        return data;
    });
}