import config from '../config';
import { handleResponse, authHeader } from '../helpers';

// device
/*
    alias: "000D6F7FE066"
    co_a: null
    co_b: null
    co_c: null
    co_d: null
    epc: "CAB0F06E00000000007FE066"
    obuid: 1870651494
    qty_sessions: 1
    tag_update_time: 1604696057.905
    tid: 1870651494
    type: "ssl"
// filter by type SSL
*/

export const deviceService = {
    list,
};

function list() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${config.API_EHOOK}/get`, requestOptions)
        .then(handleResponse)
        .then(devices => {
            return devices;
        });
}
