import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { withTranslation } from 'react-i18next';

class AlertAux extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: 5000,
            visible: true
        }
        this.removeAlert = this.removeAlert.bind(this)
    }

    removeAlert(){
        this.setState({visible: false})
    }

    componentDidMount() {
        setTimeout(() => this.removeAlert(), this.state.timeout);
    }

    componentDidUpdate () {
        if(!this.state.visible)
            this.setState({visible: true})
    }

    render(){
        const visibleAlert = this.state.visible;
        const { t } = this.props;

        if(visibleAlert)
        {
            return (
                <Alert severity={this.props.type}>
                    <AlertTitle>{t(this.props.type)}</AlertTitle>
                    {this.props.message}
                </Alert>
            )
        }
        else return (<div className="container"></div>)

    }

}

const translatedAlertAux = withTranslation()(AlertAux);
export { translatedAlertAux as AlertAux };