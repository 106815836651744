import React from 'react';
// import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../components';
import { withTranslation } from 'react-i18next';
import {
    TableCell,
    TableRow,
    Collapse,
    IconButton,
    DialogTitle,
    Dialog,
    Button,
    DialogActions,
    Checkbox,
    Typography,
    Box,
    Paper,
    FormControl,
    InputLabel,
    Input
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import HttpsIcon from '@material-ui/icons/Https';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { connect } from 'react-redux';
import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { userService } from '../../services/user_service';

class UsersRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            open: false,
            isEditing: false,
            isEditingUser: false,
            confirmationDialog: false,
            isDeleting: false,
            ssl_admin: false,
            ssl_user: false,
            ssl_reader: false,
            ssl_technician: false,
            userItem: {},
        };
        this.checkRoles = this.checkRoles.bind(this);
        this.blockUser = this.blockUser.bind(this);
        this.sendProtocol = this.sendProtocol.bind(this);
        this.enableChangePermissions = this.enableChangePermissions.bind(this);
        this.handleChangeUserInfo = this.handleChangeUserInfo.bind(this);
        this.enableUserChangePermissions = this.enableUserChangePermissions.bind(this);
    }

    async blockUser(active) {
        const { t } = this.props;

        await this.setState(oldState => {
            return {
                ...oldState,
                userItem: {
                    ...oldState.userItem,
                    active: active
                },
                confirmationDialog: !oldState.confirmationDialog,
            }
        })
        const { userItem } = this.state;
        const newUserItem = {
            username: userItem.username,
            email: userItem.email,
            active: parseInt(userItem.active),
            id: parseInt(userItem.id)
        }
        userService.block_unblock(newUserItem)
        .then(v => userItem.active === '1' ? toast.success(t('unlocked_with_success')) : toast.success(t('blocked_with_success')))
        .catch(v => {          
            toast.error(t('rejected'));
        });
        
    }

    sendProtocol() {
        const { t } = this.props;
        toast.success(t('protocol_successfully_sent'));
    }

    handleChangeUserInfo(event) {
        const { name, value } = event.target;
        this.setState(oldState => {
            return {
                ...oldState,
                userItem: {
                    ...oldState.userItem,
                    [name]: value,
                }
            }
        })
    }

    async enableChangePermissions() {
        const { t } = this.props;
        await this.setState(oldState => {
            return {
                ...oldState,
                isEditing: !oldState.isEditing,
            }
        });
        !this.state.isEditing && toast.success(t('update_success'));
    }

    async enableUserChangePermissions() {
        const { t } = this.props;
        await this.setState(oldState => {
            return {
                ...oldState,
                isEditingUser: !oldState.isEditingUser,
            }
        });
        !this.state.isEditingUser && toast.success(t('update_success'));
    }

    componentDidMount() {
        this.checkRoles();
        this.setState(oldState => {
            return {
                ...oldState,
                userItem: {
                    ...this.props.userItem
                }
            }
        })
    }

    checkRoles() {
        const { userItem } = this.props;        
        userItem.permissions.forEach((role) => {
            switch(role) {
                case 'ssl-admin':
                    this.setState(oldState => ({...oldState, ssl_admin: true}));
                    break;
                case 'ssl-user':
                    this.setState(oldState => ({...oldState, ssl_user: true}));
                    break;
                case 'ssl-reader':
                    this.setState(oldState => ({...oldState, ssl_reader: true}));
                    break;
                case 'ssl-technician':
                    this.setState(oldState => ({...oldState, ssl_technician: true}));
                    break;
                default:
            }
        })
    }

    render() {
        // const path = this.props.props.match.path
        const { t, classes, user } = this.props;
        const { 
            open,
            confirmationDialog,
            isEditing,
            isDeleting,
            isEditingUser,
            ssl_admin,
            ssl_user,
            ssl_reader,
            ssl_technician,
            userItem
        } = this.state;

        return (
            <>
                <TableRow
                    key={userItem.id}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small" 
                            onClick={() => this.setState({open : !open})}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        {userItem.id}
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {userItem.username}
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {userItem.company_name}
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {
                            ssl_admin ? 
                            <CheckIcon className={ classes.checked } /> :
                            <ClearIcon className={ classes.un_checked } />
                        }
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {
                            ssl_user ? 
                            <CheckIcon className={ classes.checked } /> :
                            <ClearIcon className={ classes.un_checked } />
                        }
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {
                            ssl_reader ? 
                            <CheckIcon className={ classes.checked } /> :
                            <ClearIcon className={ classes.un_checked } />
                        }
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        {
                            ssl_technician ? 
                            <CheckIcon className={ classes.checked } /> :
                            <ClearIcon className={ classes.un_checked } />
                        }
                    </TableCell>
                    <TableCell>
                        <IconButton
                            onClick={() => this.setState({confirmationDialog: true})}
                            linkButton={true} 
                            variant="contained"
                            className={classes.icon_default}
                        >
                            {isDeleting 
                                ? <LoadingSpinner />
                                : 
                                (
                                    userItem.active === '1' ? <LockOpenIcon /> : <HttpsIcon />
                                )
                            }
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {
                                !isEditing &&
                                <Paper elevation={2}>
                                    <Box padding="20px">
                                        <div>
                                            <Typography
                                                className={classes.sec_title_default}
                                                variant="h5"
                                                gutterBottom
                                                component="div"
                                            >
                                                {t('user_information')}
                                            </Typography>
                                        </div>
                                        <div>
                                            <form>
                                                <div>
                                                    <FormControl>
                                                        <InputLabel>{t('username')}</InputLabel>
                                                        <Input 
                                                            type="text" 
                                                            value={ userItem.username }
                                                            name="username"
                                                            disabled={!isEditingUser}
                                                            onChange={this.handleChangeUserInfo}/>
                                                    </FormControl>
                                                    {   userItem.username &&
                                                        <Typography 
                                                            variant="caption" 
                                                            display="block" 
                                                            className="form-text text-muted"
                                                        >
                                                                {t('max_of_characters')} 30
                                                        </Typography>
                                                    }
                                                </div>
                                                <div>
                                                    <FormControl>
                                                        <InputLabel>{t('email')}</InputLabel>
                                                        <Input
                                                            type="text"
                                                            value={ userItem.email }
                                                            name="email"
                                                            disabled={!isEditingUser}
                                                            onChange={this.handleChangeUserInfo}/>
                                                    </FormControl>
                                                    {
                                                        userItem.email &&
                                                        <Typography
                                                            variant="caption"
                                                            display="block"
                                                            className="form-text text-muted"
                                                        >
                                                            {t('max_of_characters')} 30
                                                        </Typography>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        isEditingUser &&
                                                        (
                                                            <Button
                                                                className={classes.button_default}
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={this.enableUserChangePermissions}
                                                            >
                                                                { t('save') + ' ' + t('username') }
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </Box>
                                </Paper>
                            }
                            {
                                !isEditingUser && (
                                    !isEditing ?
                                    <>
                                        {
                                            user.roles.includes('SSL-ADMIN') &&
                                            <>
                                                {/* <Button
                                                    className={classes.button_edit_user}
                                                    onClick={this.sendProtocol}
                                                >
                                                    {t('send_password_protocol')}
                                                </Button> */}
                                                {/* <Button
                                                    className={classes.button_edit_user}
                                                    onClick={this.enableChangePermissions}
                                                >
                                                    {t('change_users_roles')}
                                                </Button> */}
                                            </>
                                        }
                                        
                                        {/* <Button
                                            className={classes.button_edit_user}
                                            onClick={this.enableUserChangePermissions}
                                        >
                                            {t('edit') + ' ' + t('username')}
                                        </Button> */}
                                    </> :
                                    <>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="center">{t('ssl-admin')}</TableCell>
                                            <TableCell align="center">{t('ssl-user')}</TableCell>
                                            <TableCell align="center">{t('ssl-reader')}</TableCell>
                                            <TableCell align="center">{t('ssl-technician')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="center">
                                                <Checkbox
                                                    color='primary'
                                                    onClick={ () => this.setState(oldState => ({...oldState, ssl_admin: !oldState.ssl_admin})) }
                                                    checked={ ssl_admin }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox
                                                    color='primary'
                                                    onClick={ () => this.setState(oldState => ({...oldState, ssl_user: !oldState.ssl_user})) }
                                                    checked={ ssl_user }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox
                                                    color='primary'
                                                    onClick={ () => this.setState(oldState => ({...oldState, ssl_reader: !oldState.ssl_reader})) }
                                                    checked={ ssl_reader }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Checkbox
                                                    color='primary'
                                                    onClick={ () => this.setState(oldState => ({...oldState, ssl_technician: !oldState.ssl_technician})) }
                                                    checked={ ssl_technician }
                                                />
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                        <Button
                                            className={classes.button_default}
                                            onClick={this.enableChangePermissions}
                                        >
                                            {t('save')}
                                        </Button>
                                    </>
                                )
                            }
                        </Collapse>
                    </TableCell>
                </TableRow>

            <Dialog 
                onClose={() => this.setState({confirmationDialog: false})} 
                aria-labelledby="simple-dialog-title" 
                open={confirmationDialog}
                fullWidth='sm'
                maxWidth='sm'
            >
                    <DialogTitle 
                        id="simple-dialog-title"
                        className={classes.sec_title_default}
                    >
                        {
                            userItem.isActive ?
                            t('confirm_block') :
                            t('confirm_unlock')
                        }
                    </DialogTitle>
                    <DialogActions>
                        <Button 
                            onClick={() => {
                                userItem.active === '1' ?
                                this.blockUser('0') :
                                this.blockUser('1');
                            }} 
                            color="primary"
                            variant="contained" 
                            className={classes.button_green_default}
                            >
                            {t('yes')}
                        </Button>
                        <Button 
                            onClick={() => this.setState({confirmationDialog: false})}
                            color="primary" 
                            variant="contained" 
                            autoFocus
                            className={classes.button_red_default}
                            >
                            {t('no')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

UsersRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedUsersRow = connect(mapState)(UsersRow);
const translatedUsersRow = withTranslation()(connectedUsersRow);
const styledUsersRow = withStyles(Styles)(translatedUsersRow)
export { styledUsersRow as UsersRow };